.radio-custom .ant-radio-wrapper {
    border: 2px solid #ccc;
    border-radius: 0px;
    padding: 4px;
    font-weight: 400;
}

.radio-custom .ant-radio-wrapper-checked {
    border: 2px solid blue;
    background-color: rgba(0, 191, 255, 0.101);
    color: blue;
    border-radius: 0px;
    padding: 4px;
}

.radio-custom .ant-radio {
    position: absolute;
    left: -9999px;
    overflow: hidden;
}

.radio-custom .ant-radio+* {
    display: block;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    overflow: hidden;
}