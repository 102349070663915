@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-chapter {
    @apply text-[35px] md:text-[50px] font-bold;
  }
  .text-section {
    @apply text-[18px] md:text-[23px] font-thin leading-[30px] md:leading-[35px];
  }
  .text-subsection {
    @apply text-[15px] md:text-[20px] font-thin leading-[22px] md:leading-[25px];
  }
  .text-paragraph {
    @apply text-[14px] md:text-[16px] font-normal leading-[16px] md:leading-[18px];
  }
  .text-subparagraph {
    @apply text-[12px] md:text-[14px] font-normal leading-[15px] md:leading-[16px];
  }
}
