@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Glitch&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* .ant-menu-item-selected{
    background-color: aqua !important;
    border-radius: 3em !important;
    border-color: brown !important;
    box-shadow: 2px !important;
    padding: -10px !important;
}

 */
